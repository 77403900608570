<template>
  <div
    :class="{
      'sp-is-unread-ticket': row.isUnread,
    }"
    style="width: max-content"
  >
    <ticket-status-component :ticket="row"/>
  </div>
</template>

<script>

  import TicketStatusComponent from '@/entities/account/features/message-center/components/ticket-status.component';

  export default {
    name: 'TicketStatusCellComponent',
    components: { TicketStatusComponent },
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {};
    },

    computed: {},

    methods: {},

  };
</script>

<style lang="scss">
  .sp-extra-small {
    width: 6px;
    height: 6px;
    font-size: 6px;
  }

  .sp-is-unread-ticket {
    font-weight: 500;
  }
</style>
