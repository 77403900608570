<template>
  <div class="sp-ticket-status">
    <i
      :class="`fas fa-circle sp-ticket-status-icon ${statusIconClass}`"
    />
    <div class="mx-1">
      {{ status }}
    </div>
  </div>
</template>

<script>

  import isClosedTicket from '@/entities/account/features/message-center/helpers/is-closed-ticket.helper';

  export default {
    name: 'TicketStatusComponent',
    props: {
      ticket: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {};
    },

    computed: {
      status() {
        if (isClosedTicket(this.ticket)) {
          return 'Closed';
        }

        return 'Open';
      },
      statusIconClass() {
        if (this.status === 'Closed') {
          return 'sp-ticket-status-icon-closed';
        }

        return '';
      },
    },

    methods: {},

  };
</script>

<style lang="scss" scoped>
  .sp-ticket-status {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #E7EBF1;
    padding: 0.15rem 0.5rem;

    &-icon {
      margin-right: 0.25rem;
      color: #FFA800;
      font-size: 0.5rem;
      &-closed {
        color: #B6C0D3;
      }
    }
  }
</style>
