<template>
  <div class="sp-message-center-list-mobile-item">
    <div>
      <div class="sp-message-center-list-mobile-item-ticket">
        #{{ ticketId }}
      </div>
      <div class="sp-message-center-list-mobile-item-type">
        {{ type }}
      </div>
      <div class="sp-message-center-list-mobile-item-date">
        {{ openingTime }}
      </div>
    </div>
    <div style="display: flex; align-items: center">
      <ticket-status-component :ticket="ticket"/>
      <nav-button-component
        style="margin-left: 10px;"
        icon="fa-chevron-right"
      />
    </div>
  </div>
</template>

<script>
  import TicketStatusComponent from '@/entities/account/features/message-center/components/ticket-status.component.vue';
  import extractTicketTopic from '@/entities/account/features/message-center/helpers/extract-ticket-topic.helper';
  import NavButtonComponent from '@/shared/components/nav-button.component.vue';

  export default {
    name: 'MessageCenterListMobileItemComponent',
    components: { NavButtonComponent, TicketStatusComponent },
    props: {
      ticket: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {};
    },

    computed: {
      ticketId() {
        return new Date(this.ticket.ts).getTime();
      },
      type() {
        return extractTicketTopic(this.ticket) || 'None';
      },
      openingTime() {
        const date = new Date(this.ticket.ts);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${day}.${month}.${year}, ${hours}:${minutes}:${seconds}`
      },
    },

    methods: {
    },

  };
</script>

<style lang="scss" scoped>
  .sp-message-center-list-mobile-item {
    border-radius: 10px;
    border: 1px solid #F0F2F6;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &-ticket {
      font-weight: 500;
    }
    &-type {
      font-weight: 400;
    }
    &-date {
      color: #B6C0D3;
      font-weight: 400;
    }
  }
</style>
