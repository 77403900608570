<template>
  <div
    :class="{
      'sp-message-center': true,
      'sp-message-center-mobile': isMobile,
    }"
  >
    <div
      ref="header"
      class="sp-message-center-header"
    >
      <div style="display: flex; align-items: center">
        <nav-button-component
          v-if="isMobile"
          @click.native="goBack"
        />
        <div style="margin-left: 10px">
          Messaging Center
        </div>
      </div>
      <div>
        <button
          class="sp-message-center-header-new-issue-button"
          @click="onNewIssue"
        >
          New Issue
        </button>
      </div>
    </div>
    <div
      v-if="isMobile"
    >
      <message-center-list-mobile-component
        :tickets="tickets"
        @tickets:select="onSelectTicket"
      />
      <chat-widget-component
        v-if="showMobileChat"
        ref="widget"
        :chat-user="chatUser"
        :selected-ticket="selectedTicket"
        :messages="currentMessages"
        class="sp-message-center-content-chat-wrapper"
        header-class="sp-message-center-content-chat-header"
        @ticket:new="onNewTicket"
        @message:new="onNewMessage"
        @messages:more="loadMoreMessages"
        @attachment:new="onNewAttachment"
        @close="onCloseChat"
      />
    </div>
    <div
      v-else
      class="sp-message-center-content"
    >
      <div class="sp-message-center-content-list">
        <message-center-list-component
          :tickets="ticketsToShow"
          :selected-types="selectedTypes"
          :sort-key="ticketsSortKey"
          :selected-ticket="selectedTicket"
          @tickets:sort="onSort"
          @tickets:select="onSelectTicket"
          @type:select="onSelectType"
        />
      </div>
      <div class="sp-message-center-content-chat">
        <chat-widget-component
          ref="widget"
          :chat-user="chatUser"
          :selected-ticket="selectedTicket"
          :messages="currentMessages"
          :disabled-new-messages="isNewTicketCreating"
          :show-mobile-new-issue-button="false"
          class="sp-message-center-content-chat-wrapper"
          header-class="sp-message-center-content-chat-header"
          :is-fixed="false"
          @ticket:new="onNewTicket"
          @message:new="onNewMessage"
          @messages:more="loadMoreMessages"
          @attachment:new="onNewAttachment"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import MessageCenterListComponent
    from '@/entities/account/features/message-center/components/message-center-list.component';
  import ChatSupportService from '@/shared/services/chat-support.service';
  import isClosedTicket from '@/entities/account/features/message-center/helpers/is-closed-ticket.helper';
  import extractTicketTopic from '@/entities/account/features/message-center/helpers/extract-ticket-topic.helper';
  import { orderBy } from 'lodash';
  import ChatWidgetComponent from '@/shared/features/chat-widget/components/chat-widget.component.vue';
  import MessageCenterListMobileComponent
    from '@/entities/account/features/message-center/components/message-center-list-mobile.component.vue';
  import NavButtonComponent from '@/shared/components/nav-button.component.vue';

  const chatSupportService = new ChatSupportService();
  export default {
    name: 'MessageCenterContainer',
    components: {
      NavButtonComponent,
      MessageCenterListMobileComponent,
      ChatWidgetComponent,
      MessageCenterListComponent,
    },
    props: {},

    data() {
      return {
        chatUser: null,
        isMobile: false,
        tickets: [],
        selectedTypes: [],
        ticketsTotal: 0,
        ticketsSortKey: '-ticket',
        selectedTicket: null,
        showMobileChat: false,

        debounceLoadMessagesTimer: null,
        messages: {
          newTicket: [],
        },
        messagesTotal: {
          // id: count
        },
        chatScrollPosition: 0,
        isNewTicketCreating: false,

        resizeCallback: null,
        chatWidgetCloseCallback: null,
      };
    },

    computed: {
      ticketsToShow() {
        return this.tickets.filter((ticket) => {
          if (this.selectedTypes.length < 1 || this.selectedTypes.includes('All')) {
            return true;
          }

          return this.selectedTypes.includes(extractTicketTopic(ticket));
        });
      },
      currentMessages() {
        if (!this.selectedTicket) {
          return this.messages.newTicket;
        }

        return this.messages[this.selectedTicket._id] || [];
      },
    },

    beforeMount() {
      this.initChat();
      this.isMobile = window.innerWidth < 800;
      this.resizeCallback = this.onResize.bind(this);

      window.addEventListener('resize',  this.resizeCallback);

      this.chatWidgetCloseCallback = this.onCloseChat.bind(this);
      this.$bus.$emit('updateTitle', 'Messaging Center');
      this.$bus.$on('ChatWidgetClose', this.chatWidgetCloseCallback);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeCallback);
      this.$bus.$off('ChatWidgetClose', this.chatWidgetCloseCallback);
      this.resizeCallback = null;
      chatSupportService.destroy();
    },
    methods: {
      onResize() {
        this.isMobile = window.innerWidth < 800;
        if (!this.isMobile) {
          document.body.style.overflow = 'auto';
        }

        if (this.isMobile && this.showMobileChat) {
          document.body.style.overflow = 'hidden';
        }
      },
      goBack() {
        this.$router.push('/account');
      },
      onCloseChat() {
        this.showMobileChat = false;
        document.body.style.overflow = 'auto';
        this.$bus.$emit('VisibleSupportChat', false);
      },
      async loadMoreMessages(e) {
        // TODO: refactor this function
        if (!this.selectedTicket) {
          return;
        }

        const ticketId = this.selectedTicket._id;
        const total = this.messagesTotal[ticketId];

        if (!total || total <= this.messages[ticketId].length) {
          return;
        }

        if (this.debounceLoadMessagesTimer) {
          clearTimeout(this.debounceLoadMessagesTimer);
        }

        this.debounceLoadMessagesTimer = setTimeout(async () => {
          if (!this.areMessagesLoading && e.target.scrollTop < 400 && this.chatScrollPosition > e.target.scrollTop) {
            this.areMessagesLoading = true;
            const topPosition = e.target.scrollHeight - e.target.scrollTop;

            try {
              const { messages, total: totalRes } = await chatSupportService.loadMessagesForTicket(
                ticketId,
                this.messages[ticketId].length,
              );
              this.messages = {
                ...this.messages,
                [ticketId]: [...messages, ...this.messages[ticketId]],
              };
              this.messagesTotal[ticketId] = totalRes;

              this.$nextTick(() => {
                this.$refs.widget.$refs.chat.scrollTop = e.target.scrollHeight - topPosition;
              });
            } catch (err) {
              console.log('err', err);
              // this.$showNotify('error', 'Cannot load more messages due to error');
            } finally {
              this.areMessagesLoading = false;
            }
          }

          this.chatScrollPosition = e.target.scrollTop;
        });
      },
      scrollChatToBottom() {
        this.$refs.widget?.scrollToBottom();
      },
      async onSelectTicket(ticket) {
        this.selectedTicket = ticket;
        this.showMobileChat = true;
        if (!this.messages[ticket._id]) {
          try {
            const { messages, total } = await chatSupportService.loadMessagesForTicket(ticket._id);
            this.messages = {
              ...this.messages,
              [ticket._id]: messages,
            };
            this.messagesTotal[ticket._id] = total;
          } catch (err) {
            // this.$showNotify('error', 'Cannot load messages for ticket due to error.');
          }
        }
        this.readTicket(ticket._id);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        if (this.isMobile) {
          document.body.style.overflow = 'hidden';
        }
        this.onResize();
        this.scrollChatToBottom();
      },
      async readTicket(ticketId) {
        try {
          await chatSupportService.readTicket(ticketId);
          this.changeTicketUnreadStatus(ticketId, false);
        } catch (err) {
          console.log('Cannot mark ticket as read', err);
        }
      },
      changeTicketUnreadStatus(ticketId, isUnread) {
        this.tickets = this.tickets.map((t) => {
          if (t._id !== ticketId) {
            return t;
          }

          return { ...t, isUnread };
        });
      },
      onSelectType(type) {
        const { value } = type;
        if (!value) {
          return this.selectedTypes = [];
        }

        if (value === 'All' && !this.selectedTypes.includes('All')) {
          return this.selectedTypes = ['All'];
        }

        if (value !== 'All' && this.selectedTypes.includes('All') && !this.selectedTypes.includes(value)) {
          return this.selectedTypes = [...this.selectedTypes.filter((t) => t !== 'All'), value];
        }

        if (this.selectedTypes.includes(value)) {
          return this.selectedTypes = this.selectedTypes.filter((t) => t !== value);
        }

        this.selectedTypes = [...this.selectedTypes, value];
      },
      onSort(sort) {
        const sortOrder = sort.startsWith('-') ? 'desc' : 'asc';
        const sortField = sort.replace('-', '');
        const sortMap = {
          ticket: (row) => (new Date(row.ts).getTime()),
          status: (row) => (isClosedTicket(row) ? 'Closed' : 'Open'),
          opening_time: (row) => (new Date(row.ts).getTime()),
          type: (row) => extractTicketTopic(row) || 'None',
        };

        this.ticketsSortKey = sort;
        this.tickets = orderBy(this.tickets, (item) => {
          const sortValue = sortMap[sortField](item);
          return Number.parseFloat(sortValue) || sortValue;
        }, sortOrder);
      },
      async initChat() {
        try {
          await chatSupportService.init();
          this.chatUser = chatSupportService.getUser();
          const unreadThreadIds = await this.getUnreadTickets();
          const { threads, total } = await chatSupportService.loadTickets();
          this.ticketsTotal = total;
          this.tickets = threads.map((t) => ({
            ...t,
            isUnread: !!unreadThreadIds.find((id) => id === t._id),
          }));
          this.onSort(this.ticketsSortKey);
          chatSupportService.subscribeOnNewMessages((arg) => {
            this.scrollChatToBottom();
            this.handleNewMessage(arg.body.args[0]);
          })
        } catch (err) {
          console.log('ERR', err);
        }
      },
      async getUnreadTickets() {
        try {
          const { subscription } = await chatSupportService.getRoomDetails();

          return subscription.tunread || [];
        } catch (err) {
          console.log('err', err);
          return [];
        }
      },
      async onNewAttachment(file) {
        if (!file) {
          return;
        }

        if (!this.selectedTicket) {
          return;
        }

        try {
          const ticketId = this.selectedTicket?._id;
          const tempId = (Math.random() + 1).toString(36).substring(7);

          const reader = new FileReader();
          // TODO: handle error signal
          reader.onload = async (e) => {
            const draftMessage = {
              tempId,
              ts: new Date(),
              u: {
                _id: this.chatUser.id,
              },
              tmid: ticketId,
              msg: '',
              _inProgress: true,
              _isFailed: false,
              _file: file,
              attachments: [{
                base64src: e.target.result,
              }],
            };

            try {
              this.addMessage(draftMessage);
              this.scrollChatToBottom();
              const { message } = await chatSupportService.uploadFileToThread(ticketId, file);
              this.replaceMessage(ticketId, draftMessage, message);
              this.scrollChatToBottom();
            } catch (err) {
              if (err.response?.status === 413) {
                // this.removeMessage(ticketId, draftMessage.tempId);
                // this.$showNotify('error', 'File is too large.');
                return;
              }

              // this.replaceMessage(draftMessage, {
              //   ...draftMessage,
              //   _inProgress: false,
              //   _isFailed: true,
              // });
              // this.$showNotify('error', 'Cannot send attachment due to error.');
              console.log('ERR:', err);
            }
          };
          reader.readAsDataURL(file);
        } catch (err) {
          console.log('Cannot upload file', err);
          // this.$showNotify('error', 'Cannot upload file due to error.');
        }
      },
      async onNewTicket({ msg, ticket }) {
        if (this.isNewTicketCreating) {
          return;
        }

        try {
          this.isNewTicketCreating = true;
          const response = await chatSupportService.openTicket({
            ...ticket,
            msg,
          });
          this.selectedTicket = response.ticket;
          this.addMessage(response.issue);
          this.scrollChatToBottom();
        } catch (err) {
          console.log('err', err);
        } finally {
          this.isNewTicketCreating = false;
        }
      },
      onNewIssue() {
        this.selectedTicket = null;
        this.showMobileChat = true;
      },
      async onNewMessage(msg) {
        const { message: res } = await chatSupportService.sendMessageToThread(this.selectedTicket._id, msg);
        this.addMessage(res);
      },
      handleNewMessage(message) {
        message.ts = new Date(message.ts.$date);
        if (!message.tmid) {
          this.handleTicketUpdate(message);
          return;
        }

        if (message.u.username === this.chatUser.username) {
          return;
        }

        this.addMessage(message);
        if (!this.selectedTicket || this.selectedTicket._id !== message.tmid) {
          this.changeTicketUnreadStatus(message.tmid, true);
        } else {
          this.readTicket(message.tmid);
        }
      },
      handleTicketUpdate(message) {
        const ticketExists = this.tickets.find((t) => t._id === message._id);
        if (!ticketExists) {
          this.tickets = [message, ...this.tickets];
          this.selectedTicket = this.tickets[0];
          return;
        }

        this.tickets = this.tickets.map((t) => {
          if (t._id !== message._id) {
            return t;
          }

          return { ...t, ...message };
        });
        if (this.selectedTicket?._id === message._id) {
          this.selectedTicket = message;
        }
      },
      addMessage(message) {
        this.messages = {
          ...this.messages,
          [message.tmid]: [
            ...(this.messages[message.tmid] || []),
            message,
          ],
        };
      },

      replaceMessage(ticketId, oldMessage, newMessage) {
        const ticketMessages = this.messages[ticketId].filter((msg) => !msg.tempId || msg.tempId !== oldMessage.tempId);
        ticketMessages.push(newMessage);
        this.messages = {
          ...this.messages,
          [ticketId]: ticketMessages,
        };
      },
    },

  };
</script>

<style lang="scss">
  .sp-message-center-content-chat-wrapper {
    border: 1px solid #F0F2F6;
  }
  .sp-message-center-content-chat-header {
    background-color: white;
    color: #343434;
    border-bottom: 1px solid #F0F2F6;
    i {
      display: none;
    }
  }

  .sp-message-center {
    color: #343434;
    margin-top: 50px;
    &-mobile {
      margin-top: 0;
    }
    &-header {
      font-weight: 500;
      font-size: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      &-new-issue-button {
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        padding: 0px 10px;
        border-radius: 5px;
        background-color: #21A66E;
        color: #fff;
      }
    }

    &-content {
      display: flex;
      column-gap: 0.5rem;
      margin-bottom: 1rem;
      &-list {
        flex: 1;
        border-radius: 10px;
        border: 1px solid #F0F2F6;

        table {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

      &-chat {
      }
    }
  }
</style>
