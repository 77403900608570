<template>
  <div
    class="sp-nav-button"
  >
    <i
      :class="{
        'far': true,
        [icon]: true,
      }"
    />
  </div>
</template>

<script>
  export default {
    name: 'NavButtonComponent',
    props: {
      icon: {
        type: String,
        default: 'fa-chevron-left',
      },
    },

    data() {
      return {};
    },

    computed: {},

    methods: {},

  };
</script>

<style lang="scss" scoped>
  .sp-nav-button {
    border: 1px solid #E7EBF1;
    width: 23px;
    height: 23px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 10px;
    }
  }
</style>
