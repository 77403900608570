<template>
  <div
    :class="{
      'sp-is-unread-ticket': row.isUnread,
    }"
  >
    {{ openingTime }}
  </div>
</template>

<script>
  import { formatDateTime } from '@/shared/helpers/date';

  export default {
    name: 'TicketOpeningTimeCellComponent',
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {};
    },

    computed: {
      openingTime() {
        return formatDateTime(new Date(this.row.ts));
      },
    },

    methods: {},

  };
</script>

<style lang="scss" scoped>
  .sp-is-unread-ticket {
    font-weight: 500;
  }
</style>
