<template>
  <message-center-container/>
</template>

<script>
  import MessageCenterContainer from '@/entities/account/features/message-center/message-center.container';

  export default {
    name: 'MessageCenterView',
    components: { MessageCenterContainer },
    props: {},

    data() {
      return {};
    },

    computed: {},

    methods: {},

  };
</script>

<style lang="scss" scoped>

</style>
