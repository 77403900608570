<template>
  <div
    :class="{
      'sp-is-unread-ticket': row.isUnread,
    }"
  >
    {{ type }}
  </div>
</template>

<script>

  import extractTicketTopic from '@/entities/account/features/message-center/helpers/extract-ticket-topic.helper';

  export default {
    name: 'TicketTypeCellComponent',
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {};
    },

    computed: {
      type() {
        return extractTicketTopic(this.row) || 'None';
      },
    },

  };
</script>

<style lang="scss" scoped>
  .sp-is-unread-ticket {
    font-weight: 500;
  }
</style>
