<template>
  <div class="sp-table-wrapper">
    <table class="sp-table">
      <thead>
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
          >
            <div
              class="sp-table-th-wrap"
              @click="() => onSort(column)"
            >
              <div class="sp-table-th-label">
                {{ column.title }}
              </div>
              <i
                :class="{
                  'fas fa-sort-up sp-table-th-sort': true,
                  'fas fa-sort-up sp-table-th-sort-selected': sortField === column.key,
                  'fas fa-sort-up sp-table-th-sort-desc': sortOrder === 'desc' && sortField === column.key,
                }"
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          :class="{
            'sp-table-selected-row': item[selectedKey] === selected
          }"
          @click="() => onSelectRow(item)"
        >
          <td
            v-for="(column, index) in columns"
            :key="index"
          >
            <slot
              name="default"
              :column="column.key"
              :row="item"
            >
              {{ item[column.key] }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'TableComponent',
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
      items: {
        type: Array,
        default: () => [],
      },
      isFullWidth: {
        type: Boolean,
        default: true,
      },
      sort: {
        type: String,
        default: '',
      },
      selectedKey: {
        type: String,
        default: '',
      },
      selectedValue: {
        type: [Number, String],
        default: '',
      },
    },

    data() {
      return {

      };
    },

    computed: {
      selected() {
        if (!this.selectedKey || [undefined, null].includes(this.selectedValue)) {
          return null;
        }

        const index = this.items.findIndex((item) => item[this.selectedKey] === this.selectedValue);

        if (index < 0) {
          return null;
        }

        return this.items[index][this.selectedKey];
      },
      sortField() {
        return this.sort?.replace('-', '');
      },
      sortOrder() {
        return this.sort?.startsWith('-') ? 'desc' : 'asc';
      },
    },

    methods: {
      onSelectRow(item) {
        this.$emit('item:select', item);
      },
      onSort(field) {
        if (!field.sortable) {
          return;
        }

        let key = field.key;
        console.log('this.sortOrder', this.sortOrder);
        console.log('this.sortField', this.sortField);
        if (key === this.sortField) {
          key = this.sortOrder === 'asc' ? `-${key}` : key;
        }
        this.$emit('items:sort', key);
      },
    },

  };
</script>

<style lang="scss" scoped>
  .sp-table-wrapper {

  }

  .sp-table {
    width: 100%;

    &-th-label {
      white-space: nowrap;
    }

    &-th-wrap {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-th-sort {
      margin-left: 0.5rem;
      height: 8px;
      &-desc {
        transform: rotate(180deg)
      }
      &-selected {
        color: #209B67;
      }
    }

    tr {
      cursor: pointer;
    }

    tr.sp-table-selected-row, tr:hover {
      background-color: #f2f4f7;
    }

    th, tr {
      border-bottom: 1px solid #F0F2F6;
      border-collapse: collapse;
    }

    tr:last-child {
      border-bottom: none;
    }

    th {
      background-color: #F0F2F6;
      font-size: 14px;
      font-weight: 400;
      color: #96A0B6;
    }

    th {
      padding: 0.34rem 0.75rem;
    }

    td {
      padding: 0.3rem 0.75rem;
    }

    tbody {
      font-size: 15px;
      font-weight: 400;
    }
  }
</style>
