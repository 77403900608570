<template>
  <div class="sp-message-center-list-mobile">
    <div
      v-for="ticket in tickets"
      class="sp-message-center-list-mobile-item"
      @click="() => onSelectTicket(ticket)"
    >
      <message-center-list-mobile-item-component
        :ticket="ticket"
      />
    </div>
  </div>
</template>

<script>
  import TableComponent from '@/shared/components/table.component';
  import TicketNumberCellComponent
    from '@/entities/account/features/message-center/components/cells/ticket-number-cell.component.vue';
  import TicketStatusCellComponent
    from '@/entities/account/features/message-center/components/cells/ticket-status-cell.component.vue';
  import TicketOpeningTimeCellComponent
    from '@/entities/account/features/message-center/components/cells/ticket-opening-time-cell.component.vue';
  import TicketTypeCellComponent
    from '@/entities/account/features/message-center/components/cells/ticket-type-cell.component.vue';
  import isClosedTicket from '@/entities/account/features/message-center/helpers/is-closed-ticket.helper';
  import extractTicketTopic from '@/entities/account/features/message-center/helpers/extract-ticket-topic.helper';
  import { orderBy } from 'lodash';
  import FormDropdownComponent from '@/shared/components/form-components/form-dropdown.component.vue';
  import MessageCenterListMobileItemComponent
    from '@/entities/account/features/message-center/components/message-center-list-mobile-item.component.vue';

  export default {
    name: 'MessageCenterListMobileComponent',
    components: { MessageCenterListMobileItemComponent, FormDropdownComponent, TableComponent },
    props: {
      tickets: {
        type: Array,
        default: () => [],
      },
      selectedTicket: {
        type: Object,
        default: null,
      },
      selectedTypes: {
        type: Array,
        default: () => [],
      },
      sortKey: {
        type: String,
        default: 'ticket',
      },
    },

    data() {
      return {};
    },

    computed: {
    },

    methods: {
      onSelectTicket(ticket) {
        this.$emit('tickets:select', ticket);
      },
    },

  };
</script>

<style lang="scss" scopec>
  .sp-message-center-list-mobile-item {
    margin-bottom: 0.5rem;
  }
</style>
