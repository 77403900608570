<template>
  <div
    :class="{
      'sp-ticket-number': true,
      'sp-is-unread-ticket': row.isUnread,
    }"
  >
    {{ ticketNumber }}
  </div>
</template>

<script>
  export default {
    name: 'TicketNumberCellComponent',
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {};
    },

    computed: {
      ticketNumber() {
        return new Date(this.row.ts).getTime();
      },
    },

    methods: {},

  };
</script>

<style lang="scss" scoped>
  .sp-is-unread-ticket {
    font-weight: 500;
  }
</style>
