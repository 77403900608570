<template>
  <div class="sp-message-center-list">
    <div class="message-center-list-header">
      <form-dropdown-component
        :options="options"
        :value="selectedTypes"
        placeholder="Type"
        multiple
        @select:item="onSelectType"
      />
    </div>

    <table-component
      :columns="columns"
      :items="tickets"
      :sort="sortKey"
      selected-key="_id"
      :selected-value="selectedTicket && selectedTicket._id"
      @items:sort="onSort"
      @item:select="onSelectTicket"
    >
      <template #default="{ column, row }">
        <component
          :is="getComponentForCell(column)"
          :row="row"
        />
      </template>
    </table-component>
  </div>
</template>

<script>
  import TableComponent from '@/shared/components/table.component';
  import TicketNumberCellComponent
    from '@/entities/account/features/message-center/components/cells/ticket-number-cell.component.vue';
  import TicketStatusCellComponent
    from '@/entities/account/features/message-center/components/cells/ticket-status-cell.component.vue';
  import TicketOpeningTimeCellComponent
    from '@/entities/account/features/message-center/components/cells/ticket-opening-time-cell.component.vue';
  import TicketTypeCellComponent
    from '@/entities/account/features/message-center/components/cells/ticket-type-cell.component.vue';
  import isClosedTicket from '@/entities/account/features/message-center/helpers/is-closed-ticket.helper';
  import extractTicketTopic from '@/entities/account/features/message-center/helpers/extract-ticket-topic.helper';
  import { orderBy } from 'lodash';
  import FormDropdownComponent from '@/shared/components/form-components/form-dropdown.component.vue';

  export default {
    name: 'MessageCenterListComponent',
    components: { FormDropdownComponent, TableComponent },
    props: {
      tickets: {
        type: Array,
        default: () => [],
      },
      selectedTicket: {
        type: Object,
        default: null,
      },
      selectedTypes: {
        type: Array,
        default: () => [],
      },
      sortKey: {
        type: String,
        default: 'ticket',
      },
    },

    data() {
      return {
        columns: [
          {
            key: 'ticket',
            title: 'Ticket, #',
            sortable: true,
          },
          {
            key: 'type',
            title: 'Type of the Request',
            sortable: true,
          },
          {
            key: 'status',
            title: 'Status',
            sortable: true,
          },
          {
            key: 'opening_time',
            title: 'Opening Time',
            sortable: true,
          },
        ],
        options: [
          'All',
          'Product',
          'My Order',
          'Delivery',
          'Payment',
          'Returns',
          'Other',
        ].map((value) => ({
          value,
          label: value,
        })),
      };
    },

    computed: {
    },

    methods: {
      getComponentForCell(field) {
        const components = {
          ticket: TicketNumberCellComponent,
          status: TicketStatusCellComponent,
          opening_time: TicketOpeningTimeCellComponent,
          type: TicketTypeCellComponent,
        };

        return components[field];
      },
      onSort(sort) {
        this.$emit('tickets:sort', sort);
      },
      onSelectType(type) {
        this.$emit('type:select', type);
      },
      onSelectTicket(ticket) {
        this.$emit('tickets:select', ticket);
      },
    },

  };
</script>

<style lang="scss">
  .sp-message-center-list {
    .sp-table-selected-row {
      .sp-ticket-number {
        color: #48c78e;
      }
    }
  }
  .message-center-list-header {
    margin: 0.5rem;

    .select-with-search__options {
      width: auto;
    }

    .select-with-search__select {
      min-width: 5.5rem;
      width: max-content;
    }
  }
</style>
