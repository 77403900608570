import { render, staticRenderFns } from "./ticket-number-cell.component.vue?vue&type=template&id=034bffd8&scoped=true"
import script from "./ticket-number-cell.component.vue?vue&type=script&lang=js"
export * from "./ticket-number-cell.component.vue?vue&type=script&lang=js"
import style0 from "./ticket-number-cell.component.vue?vue&type=style&index=0&id=034bffd8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "034bffd8",
  null
  
)

export default component.exports